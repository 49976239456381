export const NAV_LIST = [
  {
    name: 'Home',
    hash: 'home'
  },
  {
    name: 'BTCST',
    hash: 'btcst'
  },
  // {
  //   name: 'Tau',
  //   hash: 'tau'
  // },
  {
    name: 'Stats',
    hash: 'stats',
  },
  {
    name: 'Partners',
    hash: 'partners'
  }
];
